import React, { useEffect, useState } from "react";
import HomeTop from "../components/home/Top";
import LatestNews from "../components/home/LatestNews";
import WebStories from "../components/home/WebStories";
import HomeMain from "../components/home/Main";
import Special from "../components/home/Special";
import SpecialMain from "../components/home/SpecialMain";
import Sports from "../components/home/Sports";
import Featured from "../components/home/Featured";
import { getHomeData } from "../services/articles";
import { ClockLoader } from "react-spinners";
import Ads from "../components/Ads";

function Home({ live_tv }) {
  const [loading, setLoading] = useState(false);
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchHomeData();
  }, []);

  const fetchHomeData = async () => {
    setLoading(true);
    try {
      const fetchedData = await getHomeData();
      setHomeData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching home data:", error);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          {homeData?.data?.ads?.top && (
            <Ads
              image={homeData?.data?.ads?.top?.image}
              link={homeData?.data?.ads?.top?.link}
              height=""
            />
          )}
          {homeData?.data?.top && (
            <HomeTop
              data={homeData?.data?.top}
              live_tv={live_tv}
              ad={homeData?.data?.ads?.between_fetaured}
              epaper_image={homeData?.data?.epaper}
            />
          )}
          {homeData?.data?.latest?.length > 0 && (
            <LatestNews data={homeData?.data?.latest} />
          )}
          {homeData?.data?.web_stories?.length > 0 && (
            <WebStories data={homeData?.data?.web_stories} />
          )}
          {homeData?.data?.ads?.below_stories && (
            <Ads
              image={homeData?.data?.ads?.below_stories?.image}
              link={homeData?.data?.ads?.below_stories?.link}
              width={"100%"}
              height={"auto"}
            />
          )}
          {homeData?.data?.main && <HomeMain data={homeData?.data?.main} />}
          {homeData?.data?.specials?.length > 0 && (
            <Special data={homeData?.data?.specials} />
          )}
          {homeData?.data?.ads?.below_special && (
            <Ads
              image={homeData?.data?.ads?.below_special?.image}
              link={homeData?.data?.ads?.below_special?.link}
              width={"100%"}
              height={"auto"}
            />
          )}
          {homeData?.data?.special_main && (
            <SpecialMain data={homeData?.data?.special_main} />
          )}
          {homeData?.data?.sports?.length > 0 && (
            <Sports data={homeData?.data?.sports} />
          )}
          {homeData?.data?.featured?.length > 0 && (
            <Featured data={homeData?.data?.featured} />
          )}
        </>
      ) : (
        <div className="container-fluid py-5">
          <div className="container py-5 my-5">
            <ClockLoader className="m-auto" color="#57a400" />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
