import React, { useEffect, useRef, useState } from "react";
import { getLatest } from "../services/epaper";
import { ClockLoader } from "react-spinners";
import imageMapResize from "./imageMapResizer";
import EpaperMain from "../components/EpaperMain";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

function Epaper() {
  const mapRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [epaperMenu, setEpaperMenu] = useState(false);
  const [mainPage, setMainPage] = useState({});
  const [pages, setPages] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEpaper();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      imageMapResize(mapRef.current);
    }
  }, [mainPage]);

  const fetchEpaper = async () => {
    setLoading(true);
    try {
      const pages = await getLatest();
      setPages(pages);
      setLoading(false);
      setMainPage(pages?.[0]);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching home data:", error);
    }
  };

  return loading ? (
    <div className="container-fluid py-5">
      <div className="container py-5 my-5">
        <ClockLoader className="m-auto" color="#57a400" />
      </div>
    </div>
  ) : (
    <div className="container-fluid populer-news mt-3 mb-5">
      <div className="container py-3">
        {!mainPage && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Alert className="text-center" variant="danger">
                No epaper found!!!
                <br />
                <Link to="/">Back to Home</Link>
              </Alert>
            </div>
          </div>
        )}
        {mainPage && (
          <div className="row">
            <div className="col-md-10 col-sm-12">
              <button
                onClick={() => {
                  setEpaperMenu(true);
                }}
                className="epaper-menu btn btn-info btn-sm mb-1 text-white"
                type="button"
              >
                Pages
              </button>
              <div className="m-auto">
                <div className="card p-2">
                  <div style={{ position: "relative" }}>
                    <EpaperMain
                      imageUrl={mainPage?.url}
                      areas={mainPage?.areas}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-2 col-sm-12 mobile-e-panel ${
                epaperMenu ? "active" : ""
              }`}
            >
              <div
                className="close-btn"
                onClick={() => {
                  setEpaperMenu(false);
                }}
              >
                <i className="fa fa-times-circle fa-2x text-white" />
              </div>
              <div className="container">
                <div className="row">
                  {pages.map((page) => (
                    <div
                      className="col-md-6"
                      onClick={() => {
                        setMainPage(page);
                        setEpaperMenu(false);
                      }}
                      key={page.id}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card mb-1 border border-primary">
                        <figure className="figure m-0">
                          <img
                            src={page?.url}
                            className="figure-img img-fluid rounded m-0"
                            alt="Epaper"
                          />
                          <figcaption className="figure-caption text-center p-0 m-0">
                            {page?.page_no}
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Epaper;
