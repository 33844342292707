import React from "react";
import { useNavigate } from "react-router-dom";

function Footer({ categories, socials }) {
  const navigate = useNavigate();
  const halfIndex = Math.ceil(categories.length / 2);
  const firstHalfCategories = categories.slice(0, halfIndex);
  const secondHalfCategories = categories.slice(halfIndex);
  return (
    <>
      <div className="container-fluid bg-dark footer py-5">
        <div className="container py-5">
          <div
            className="pb-4 mb-4"
            style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.08)" }}
          >
            <div className="row g-4">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <a
                  href={true}
                  className="d-flex flex-column flex-wrap align-content-center"
                >
                  <img
                    src={
                      process.env.REACT_APP_ASSET_URL + "assets/img/logo-2.png"
                    }
                    width="250"
                    alt=""
                  />
                </a>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-3 col-xl-3 col-sm-12">
              <div className="footer-item-1">
                <h5 className="mb-4 text-white">Follow US</h5>
                <div className="d-flex line-h">
                  {socials &&
                    socials.map((social) => (
                      <a
                        className="me-2 btn-md-square"
                        href={social?.media_url}
                        target="_new"
                      >
                        <img src={social?.icon?.url} alt="" />
                      </a>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-sm-12">
              <div className="d-flex flex-column text-start footer-item-3">
                <h5 className="mb-4 text-white">Menu</h5>
                <a
                  className="btn-link text-white"
                  href={true}
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-angle-right text-white me-2"></i> Home
                </a>
                <a
                  className="btn-link text-white"
                  href={true}
                  onClick={() => {
                    navigate("/about");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-angle-right text-white me-2"></i> About
                  Us
                </a>
                <a
                  className="btn-link text-white"
                  href={true}
                  onClick={() => {
                    navigate("/contact");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-angle-right text-white me-2"></i> Contact
                  Us
                </a>
                <a
                  className="btn-link text-white"
                  href={true}
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-angle-right text-white me-2"></i> Privacy
                  Policy
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-sm-12">
              <div className="d-flex flex-column text-start footer-item-3">
                {firstHalfCategories &&
                  firstHalfCategories.map((category) => (
                    <a
                      href={true}
                      className="btn-link text-white"
                      onClick={() => {
                        navigate(`/category/${category.slug}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-angle-right text-white me-2"></i>{" "}
                      {category.name}
                    </a>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-sm-12">
              <div className="d-flex flex-column text-start footer-item-3 pb-5">
                {secondHalfCategories &&
                  secondHalfCategories.map((category) => (
                    <a
                      href={true}
                      className="btn-link text-white"
                      onClick={() => {
                        navigate(`/category/${category.slug}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-angle-right text-white me-2"></i>{" "}
                      {category.name}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright bg-dark py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <span className="text-light">
                <a href={true}>
                  <i className="fas fa-copyright text-light me-2"></i>
                  Kaarshikam
                </a>
                , All right reserved.
              </span>
            </div>
            <div className="col-md-6 my-auto text-center text-md-end text-white">
              Powered By{" "}
              <a className="border-bottom" href={true}>
                Mzadcom
              </a>
            </div>
          </div>
        </div>
      </div>

      <a
        href={true}
        className="btn btn-primary border-2 border-white rounded-circle back-to-top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <i className="fa fa-arrow-up"></i>
      </a>
    </>
  );
}

export default Footer;
