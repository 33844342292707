import React, { useEffect, useState } from "react";
import { getAll } from "../services/articles";
import { useNavigate } from "react-router-dom";
import { ClockLoader } from "react-spinners";

function Pets() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalNews, setTotalNews] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchNews(1, true);
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchNews(page);
    }
  }, [page]);

  const fetchNews = async (page, initialFetch = false) => {
    setLoading(true);
    try {
      const fetchedNews = await getAll({
        page,
        limit: 12,
        category: 4,
        status: "published",
      });
      setTotalNews(fetchedNews.total);
      if (fetchedNews.data.length === 0) {
        setHasMore(false);
      }
      if (initialFetch) {
        setNews(fetchedNews.data);
      } else {
        setNews((prevNews) => [...prevNews, ...fetchedNews.data]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (news.length >= totalNews) return;
    setPage((prevPage) => prevPage + 1);
  };
  return (
    <>
      <div className="container-fluid populer-news pb-1">
        <div className="container py-5 pb-0">
          <h3>Pets</h3>
          <div className="row">
            {news.map((article) => (
              <div className="col-md-3 col-sm-6 mb-3">
                <div
                  className="card"
                  onClick={() => {
                    navigate(`/news/${article?.slug}`);
                  }}
                >
                  <img
                    src={article?.image?.url}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title ml">{article?.title}</h5>
                    <div className="d-flex justify-content-between align-items-center text-muted small">
                      <div>
                        <i className="fa fa-calendar" />
                        &nbsp;{article?.published_date}
                      </div>
                      <div>
                        <i className="fa fa-user-circle" />
                        &nbsp;{article?.user?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {!loading && hasMore && (
          <div className="text-center my-5">
            <button
              className={
                news.length >= totalNews
                  ? "btn btn-light btn-sm p-2 px-3 border"
                  : "btn btn-primary btn-sm p-2 px-3"
              }
              onClick={handleLoadMore}
              disabled={news.length >= totalNews ? true : false}
            >
              <i className="fa fa-refresh" />
              &nbsp;Load More
            </button>
          </div>
        )}
      </div>

      {loading && (
        <div className="container-fluid py-5">
          <div className="container py-5 my-5">
            <ClockLoader className="m-auto" color="#57a400" />
          </div>
        </div>
      )}
    </>
  );
}

export default Pets;
